main {
  width: 100vw;
  overflow-x: hidden;
}
body {
  display: flex;
  min-height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  color: inherit;
  text-decoration: inherit;
}

video {
  outline: none;
  margin-bottom: -7px;
}

#root {
  display: flex;
  flex: 1;
}
